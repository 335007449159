import shared from "./shared.json";
import thgQuote from "./thg-quote.json";
import user from "./user.json";

const registration = {
  ...shared,
  ...thgQuote,
  ...user,
};

export default registration;
