import type { App } from "vue";
import type { Router } from "vue-router";
import * as Sentry from "@sentry/vue";
import { getEnvironment } from "@/shared/helper/environment.helper";
import { useAccountStore } from "@/shared/stores";

export function initSentry(app: App, router?: Router) {
  if (import.meta.env.DEV || import.meta.env.CI) return;

  // eslint-disable-next-line no-console
  console.debug("Initializing Sentry...");

  const environment = getEnvironment();

  const accountStore = useAccountStore();

  Sentry.init({
    app,
    dsn: "https://75036c2db4b24d8b88145515f3cba9e0@exceptions.zweitagapps.com/13",
    tracePropagationTargets: ["localhost", /^\//],
    integrations: router
      ? [
          Sentry.browserTracingIntegration({
            router,
          }),
        ]
      : undefined,
    environment,
    release: import.meta.env.VITE_APP_REVISION?.toString(),
    // See: https://docs.sentry.io/platforms/javascript/configuration/sampling/#sampling-transaction-events
    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    },
    beforeSend: (event) => {
      return {
        ...event,
        extra: {
          ...event.extra,
          customerId: accountStore.selectedCustomerId || "",
        },
      };
    },
  });
}
