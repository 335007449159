import base from "./base.json";
import charges from "./charges.json";
import credits from "./credits.json";
import shop from "./shop.json";
import stations from "./stations.json";

const fleetAtHome = {
  ...base,
  ...charges,
  ...credits,
  ...shop,
  ...stations,
};

export default fleetAtHome;
