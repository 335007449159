import base from "./base.json";
import vehicleApplications from "./vehicle-applications.json";
import vehicleRegistration from "./vehicle-registration.json";

const thgQuote = {
  ...base,
  ...vehicleApplications,
  ...vehicleRegistration,
};

export default thgQuote;
