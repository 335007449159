import { createClient } from "@/shared/urql/urql";
import {
  UpdateBankDetailsDocument,
  type BankDetails,
} from "@/shared/generated/graphql";
import { executeMutation } from "@/shared/helper";

export const updateBankDetails = async (details: BankDetails) => {
  const urqlClient = createClient();

  const error = await executeMutation(
    (data) => data?.updateBankDetails,
    () => urqlClient.mutation(UpdateBankDetailsDocument, details).toPromise(),
  );
  if (error) throw error;
};
