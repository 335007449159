import { Roles, RoleActions, AppIdentifier } from "@/shared/@types";

const isUserRoleIncluded = <K>(
  accessor: K,
  mappings: Map<K, Array<Roles>>,
  userRoles: Array<Roles>,
) => {
  return !!mappings.get(accessor)?.some((role) => userRoles.includes(role));
};

const mappings = new Map<RoleActions, Array<Roles>>();

// Those that have a manager role can see all apps
mappings.set(RoleActions.PREVIEW_ALL_APPS, [
  Roles.FLEET_AT_HOME_MANAGER,
  Roles.WCC_MANAGER,
  Roles.WSC_MANAGER,
  Roles.WESTFALEN_ID_ACCOUNT_MANAGER,
]);
mappings.set(RoleActions.ACCESS_FLEET_AT_HOME_MANAGEMENT_APP, [
  Roles.FLEET_AT_HOME_MANAGER,
]);
mappings.set(RoleActions.ACCESS_FLEET_AT_HOME_APP, [Roles.FLEET_AT_HOME_USER]);
mappings.set(RoleActions.ACCESS_WCC_MANAGEMENT_APP, [Roles.WCC_MANAGER]);
mappings.set(RoleActions.ACCESS_WSC_MANAGEMENT_APP, [Roles.WSC_MANAGER]);
mappings.set(RoleActions.ACCESS_THG_QUOTE_APP, [Roles.THG_QUOTE_MANAGER]);
mappings.set(RoleActions.ACCESS_VIRTA, [Roles.VIRTA_USER]);
mappings.set(RoleActions.ACCESS_GAAS, [Roles.GAAS_USER]);
mappings.set(RoleActions.ACCESS_WESTFALEN_ID_ACCOUNT_MANAGEMENT_APP, [
  Roles.WESTFALEN_ID_ACCOUNT_MANAGER,
]);

export const checkPermissions = (
  action: RoleActions,
  userRoles: Array<Roles>,
) => {
  if (userRoles.length === 0) return false;

  return isUserRoleIncluded(action, mappings, userRoles);
};

const appToRolesMappings = new Map<AppIdentifier, Array<Roles>>();

appToRolesMappings.set(AppIdentifier.FinishRegistration, [
  Roles.FLEET_AT_HOME_USER,
]);

appToRolesMappings.set(AppIdentifier.Account, [
  Roles.FLEET_AT_HOME_USER,
  Roles.FLEET_AT_HOME_MANAGER,
  Roles.THG_QUOTE_MANAGER,
  Roles.WCC_MANAGER,
  Roles.WSC_MANAGER,
  Roles.VIRTA_USER,
  Roles.GAAS_USER,
  Roles.WESTFALEN_ID_ACCOUNT_MANAGER,
]);
appToRolesMappings.set(AppIdentifier.FleetAtHomeManagement, [
  Roles.FLEET_AT_HOME_MANAGER,
]);
appToRolesMappings.set(AppIdentifier.FleetAtHome, [Roles.FLEET_AT_HOME_USER]);
appToRolesMappings.set(AppIdentifier.ThgQuote, [Roles.THG_QUOTE_MANAGER]);
appToRolesMappings.set(AppIdentifier.WccManagement, [Roles.WCC_MANAGER]);
appToRolesMappings.set(AppIdentifier.WscManagement, [Roles.WSC_MANAGER]);
appToRolesMappings.set(AppIdentifier.WestfalenIdAccountManagement, [
  Roles.WESTFALEN_ID_ACCOUNT_MANAGER,
]);

export const checkPermissionsByAppIdentifier = (
  appIdentifier: AppIdentifier,
  userRoles: Array<Roles>,
) => {
  if (appIdentifier === AppIdentifier.Dashboard) return true;

  if (userRoles.length === 0) return false;
  return isUserRoleIncluded(appIdentifier, appToRolesMappings, userRoles);
};
