import { createClient } from "@/shared/urql/urql";
import { UpdatePasswordDocument } from "@/shared/generated/graphql";
import { executeMutation } from "@/shared/helper";

export const updatePassword = async (password: string) => {
  const urqlClient = createClient();

  const error = await executeMutation(
    (data) => data?.updatePassword,
    () =>
      urqlClient
        .mutation(UpdatePasswordDocument, {
          password,
        })
        .toPromise(),
  );
  if (error) throw error;
};
