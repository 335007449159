import { createClient } from "@/shared/urql/urql";
import { UpdateEmailDocument } from "@/shared/generated/graphql";
import { executeMutation } from "@/shared/helper";

export const updateEmail = async (email: string) => {
  const urqlClient = createClient();

  const error = await executeMutation(
    (data) => data?.updateEmail,
    () =>
      urqlClient
        .mutation(UpdateEmailDocument, {
          email,
        })
        .toPromise(),
  );
  if (error) throw error;
};
