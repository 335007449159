export enum Roles {
  FLEET_AT_HOME_USER = "fleet-at-home-user",
  FLEET_AT_HOME_MANAGER = "fleet-at-home-manager",
  WCC_MANAGER = "wcc-manager",
  WSC_MANAGER = "wsc-manager",
  THG_QUOTE_MANAGER = "thg-quote-manager",
  VIRTA_USER = "virta-user",
  GAAS_USER = "gaas-user",
  WESTFALEN_ID_ACCOUNT_MANAGER = "westfalen-id-account-manager",
}
