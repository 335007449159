import { i18n } from "@/shared/i18n/i18n";

export const translateError = (errorKey: string) => {
  const translationKey = `shared.error.key.${errorKey}`;
  // something with the type of the t function is wrong. Maybe an Update of i18n would help
  // this is unfortunately nothing we have in hand
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (i18n.te(translationKey)) return i18n.t(translationKey);

  return `Error: ${errorKey}`;
};
