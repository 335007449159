import base from "./base.json";
import cardsArchive from "./cards-archive.json";
import cardsOverview from "./cards-overview.json";
import orders from "./orders.json";
import shared from "./shared.json";

const wccManagement = {
  ...base,
  ...cardsArchive,
  ...cardsOverview,
  ...orders,
  ...shared,
};

export default wccManagement;
