import base from "./base.json";
import overview from "./overview.json";
import users from "./users.json";
import services from "./services.json";

const widAccountManagement = {
  ...base,
  ...overview,
  ...users,
  ...services,
};

export default widAccountManagement;
