import { createI18n } from "vue-i18n";
import { de } from "./locales/de";

export const i18nInstance = createI18n({
  messages: {
    de,
  },
  legacy: false,
  locale: "de",
});

export const i18n = i18nInstance.global;
