export enum AppIdentifier {
  Dashboard = "dashboard",
  Account = "account",
  FleetAtHome = "fleet-at-home",
  FleetAtHomeManagement = "fleet-at-home-management",
  WccManagement = "wcc-management",
  WscManagement = "wsc-management",
  Registration = "registration",
  ThgQuote = "thg-quote",
  WestfalenIdAccountManagement = "westfalen-id-account-management",
  Maintenance = "maintenance",
  FinishRegistration = "finish-registration",
}
