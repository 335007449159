import type { OperationResult } from "@urql/vue";
import { handleCombinedError } from "./handleCombinedError.helper";
import { handleErrors } from "./handleErrors.helper";

export async function executeMutation<T>(
  getNestedResult: (
    data: T | undefined,
  ) => { errors: Array<string>; success: boolean } | undefined | null,
  mutation: () => Promise<OperationResult<T>>,
  translateError = true,
) {
  const { error, data } = await mutation();
  const errors = getNestedResult(data)?.errors;

  if (error) return handleCombinedError(error);
  if (errors && errors.length > 0) return handleErrors(errors, translateError);

  return undefined;
}
