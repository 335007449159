import {
  createClient as createUrqlClient,
  cacheExchange,
  fetchExchange,
  type ClientOptions,
  Client,
} from "@urql/vue";
import { parallelQueryExchange } from "./parallelQueryExchange";

function getCsrfToken() {
  return document
    .querySelector("meta[name='csrf-token']")
    ?.getAttribute("content");
}

export function createUrqlConfig(): ClientOptions {
  return {
    url: "/api/graphql",
    requestPolicy: "network-only",
    fetchOptions: () => {
      const csrfToken = getCsrfToken();
      if (csrfToken) {
        return {
          headers: { "x-csrf-token": csrfToken },
        };
      }
      return {};
    },
    exchanges: [parallelQueryExchange, cacheExchange, fetchExchange],
  };
}

export const createClient: () => Client = () =>
  createUrqlClient(createUrqlConfig());
