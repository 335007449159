import { pipe, filter, tap } from "wonka";
import type { Exchange, Operation, OperationResult } from "@urql/vue";

export const parallelQueryExchange: Exchange = ({ forward }) => {
  const lastQueried: Record<string, number> = {};

  const isQueriedAfter = (queryId: string, queryTimestamp: number) =>
    queryId && (!lastQueried[queryId] || lastQueried[queryId] < queryTimestamp);

  const filterOutgoingResult = ({
    operation: {
      context: { parallelQueryOptions },
    },
  }: OperationResult) => {
    if (
      !parallelQueryOptions?.queryId ||
      !lastQueried[parallelQueryOptions?.queryId]
    )
      return true;
    return (
      parallelQueryOptions?.queryTimestamp >=
      lastQueried[parallelQueryOptions?.queryId]
    );
  };

  const handleIncomingOperation = (operation: Operation) => {
    const {
      context: { parallelQueryOptions },
    } = operation;

    if (
      isQueriedAfter(
        parallelQueryOptions?.queryId,
        parallelQueryOptions?.queryTimestamp,
      )
    ) {
      lastQueried[parallelQueryOptions?.queryId] =
        parallelQueryOptions?.queryTimestamp;
    }
  };

  return (ops$) => {
    const forward$ = pipe(ops$, tap(handleIncomingOperation));
    return pipe(forward(forward$), filter(filterOutgoingResult));
  };
};
