import { translateError as translateErrorHelper } from "./translateError.helper";

export const handleErrors = (
  errors: Array<string> | undefined,
  translateError = true,
) => {
  if (errors instanceof Array) {
    if (errors.length > 0)
      return translateError ? translateErrorHelper(errors[0]) : errors[0];
  }
  return undefined;
};
