import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useStorage } from "@vueuse/core";
import {
  type UserDataInput,
  type BankDetails,
  type User,
  type Customer,
  Salutation,
} from "@/shared/generated";
import { AppIdentifier, type UserData } from "@/shared/@types";
import { getSelectedOrFirstCustomerId, getUserRoles } from "@/shared/helper";
import {
  getCurrentUser,
  updateEmail as updateEmailService,
  updatePassword as updatePasswordService,
  updateUserData as updateUserDataService,
  updateBankDetails as updateBankDetailsService,
} from "@/shared/services";

export const useAccountStore = defineStore("Account", () => {
  const user = ref<undefined | User>(undefined);
  const updatingUser = ref<boolean>(false);
  const updatingUserError = ref<string | undefined>(undefined);
  const fetchingUser = ref<boolean>(false);
  const fetchingUserError = ref<string | undefined>(undefined);
  const userInitiallyFetched = ref<boolean>(false);
  const allCustomers = ref<Array<Customer>>([]);
  const selectedCustomerId = useStorage<string | undefined>(
    "selectedCustomerId",
    undefined,
  );

  const updateEmail = async (email: string) => {
    try {
      updatingUserError.value = undefined;
      updatingUser.value = true;
      await updateEmailService(email);
    } catch (error) {
      updatingUserError.value = error as string;
    } finally {
      updatingUser.value = false;
    }
  };

  const updatePassword = async (password: string) => {
    try {
      updatingUserError.value = undefined;
      updatingUser.value = true;
      await updatePasswordService(password);
    } catch (error) {
      updatingUserError.value = error as string;
    } finally {
      updatingUser.value = false;
    }
  };

  const updateUserData = async (userData: UserDataInput) => {
    try {
      updatingUserError.value = undefined;
      updatingUser.value = true;
      await updateUserDataService(userData);
    } catch (error) {
      updatingUserError.value = error as string;
    } finally {
      updatingUser.value = false;
    }
  };

  const updateBankDetails = async (details: BankDetails) => {
    try {
      updatingUserError.value = undefined;
      updatingUser.value = true;
      await updateBankDetailsService(details);
    } catch (error) {
      updatingUserError.value = error as string;
    } finally {
      updatingUser.value = false;
    }
  };

  const fetchUser = async () => {
    try {
      fetchingUserError.value = undefined;
      fetchingUser.value = true;
      const { errors, user: userResult } = await getCurrentUser();
      if (errors.length > 0) {
        fetchingUserError.value = errors[0] as string;
      }
      user.value = userResult || undefined;
      if (user.value) allCustomers.value = user.value.permissions.customers;
    } catch (error) {
      fetchingUserError.value = error as string;
    } finally {
      fetchingUser.value = false;
      if (!userInitiallyFetched.value) userInitiallyFetched.value = true;
    }
  };

  const setSelectedCustomerId = (customerId?: string) => {
    selectedCustomerId.value = customerId ?? "";
  };

  const getInitialSelectedCustomerId = (currentApp?: AppIdentifier) => {
    if (!allCustomers.value) return undefined;
    switch (currentApp) {
      case AppIdentifier.FleetAtHome:
        return user.value?.permissions.fleetAtHomeUserCustomerId ?? undefined;
      case AppIdentifier.FleetAtHomeManagement:
        return getSelectedOrFirstCustomerId(
          user.value?.permissions.fleetAtHomeManagerCustomerIds,
          selectedCustomerId.value,
        );
      case AppIdentifier.WccManagement:
        return getSelectedOrFirstCustomerId(
          user.value?.permissions.wccManagerCustomerIds,
          selectedCustomerId.value,
        );
      case AppIdentifier.WscManagement:
        return getSelectedOrFirstCustomerId(
          user.value?.permissions.wscManagerCustomerIds,
          selectedCustomerId.value,
        );
      case AppIdentifier.ThgQuote:
        return getSelectedOrFirstCustomerId(
          user.value?.permissions.thgQuoteManagerCustomerIds,
          selectedCustomerId.value,
        );
      case AppIdentifier.WestfalenIdAccountManagement:
        return getSelectedOrFirstCustomerId(
          user.value?.permissions.westfalenIdAccountManagerCustomerIds,
          selectedCustomerId.value,
        );
      default:
        return getSelectedOrFirstCustomerId(
          allCustomers.value.map((customer) => customer.id),
          selectedCustomerId.value,
        );
    }
  };

  const email = computed(() => user.value?.email);
  const bankDetails = computed(() => user.value?.bankDetails);
  const addressDetails = computed(
    () => user.value?.addressDetails || undefined,
  );
  const isUpdatingUser = computed(() => updatingUser.value);
  const isFetchingUser = computed(() => fetchingUser.value);
  const isUserInitiallyFetched = computed(() => userInitiallyFetched.value);
  const hasErrorWhileUpdatingUser = computed(
    () => updatingUserError.value !== undefined,
  );
  const hasErrorWhileFetchingUser = computed(
    () => fetchingUserError.value !== undefined,
  );
  const hasBankDetails = computed(
    () =>
      user.value?.bankDetails !== undefined && user.value?.bankDetails !== null,
  );
  const hasAddressDetails = computed(() => !!user.value?.addressDetails);
  const canStoreBankDetails = computed(
    () => user.value?.capabilities.canStoreBankDetails || false,
  );
  const canStoreAddressDetails = computed(
    () => user.value?.capabilities.canStoreAddressDetails || false,
  );
  const sapCustomerExists = computed(
    () => canStoreAddressDetails.value || canStoreBankDetails.value,
  );
  const selectedCustomer = computed(() =>
    allCustomers.value.find(
      (customer) => customer.id === selectedCustomerId.value,
    ),
  );
  const hasMultipleCustomers = computed(() => allCustomers.value.length > 1);
  const userRoles = computed(() =>
    !user.value || !selectedCustomerId.value
      ? []
      : getUserRoles(user.value.permissions, selectedCustomerId.value),
  );
  const userData = computed<UserData>(() => ({
    salutation: user.value?.salutation || Salutation.Unknown,
    firstName: user.value?.firstName || "",
    lastName: user.value?.lastName || "",
    email: email.value || "",
    customerName: selectedCustomer.value?.name || "",
  }));

  return {
    user,
    updatingUser,
    updatingUserError,
    fetchingUser,
    fetchingUserError,
    userInitiallyFetched,
    allCustomers,
    selectedCustomerId,
    email,
    bankDetails,
    addressDetails,
    isUpdatingUser,
    isFetchingUser,
    isUserInitiallyFetched,
    hasErrorWhileUpdatingUser,
    hasErrorWhileFetchingUser,
    hasBankDetails,
    hasAddressDetails,
    canStoreBankDetails,
    canStoreAddressDetails,
    sapCustomerExists,
    selectedCustomer,
    hasMultipleCustomers,
    userRoles,
    userData,
    updateEmail,
    updatePassword,
    updateUserData,
    updateBankDetails,
    fetchUser,
    setSelectedCustomerId,
    getInitialSelectedCustomerId,
  };
});
