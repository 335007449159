import { createClient } from "@/shared/urql/urql";
import { GetCurrentUserDocument } from "@/shared/generated/graphql";
import { handleCombinedError, translateError } from "@/shared/helper";

export const getCurrentUser = async () => {
  const client = createClient();

  const { error, data } = await client
    .query(GetCurrentUserDocument, {})
    .toPromise();

  if (error) throw handleCombinedError(error);
  if (!data) throw translateError("INTERNAL_ERROR");

  return data.currentUserV2;
};
