import { CombinedError } from "@urql/core";
import * as Sentry from "@sentry/vue";
import { AppIdentifier } from "@/shared/@types";
import { translateError } from "./translateError.helper";
import { MAINTENANCE_ERROR_MSG } from "./errorConstants.helper";

export const handleCombinedError = (error: CombinedError | undefined) => {
  if (error instanceof CombinedError) {
    if (error && error.graphQLErrors.length > 0) {
      const combinedError = error.graphQLErrors[0];
      // Maintenance
      if (combinedError.message === MAINTENANCE_ERROR_MSG) {
        window.location.href = `/${AppIdentifier.Maintenance}`;
        return undefined;
      }
      // Internal Error
      Sentry.captureMessage(
        `Unexpected GraphQL-Error: ${combinedError.path?.join("-")}`,
        { extra: { message: error.message } },
      );
      return translateError("INTERNAL_ERROR");
    }

    if (error && error.networkError) {
      Sentry.captureException(error.networkError);
      return translateError("NETWORK_ERROR");
    }
  }
  return undefined;
};
