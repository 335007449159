import { createPinia } from "pinia";
import urql from "@urql/vue";
import { i18nInstance } from "../i18n/i18n";
import { createUrqlConfig } from "../urql/urql";
import type { PluginDescription } from "./usePlugins";

export default [
  createPinia(),
  i18nInstance,
  [urql, createUrqlConfig()],
] as Array<PluginDescription>;
