export enum RoleActions {
  ACCESS_FLEET_AT_HOME_APP = "access-fleet-at-home-app",
  ACCESS_FLEET_AT_HOME_MANAGEMENT_APP = "access-fleet-at-home-management-app",
  ACCESS_WCC_MANAGEMENT_APP = "access-wcc-management-app",
  ACCESS_WSC_MANAGEMENT_APP = "access-wsc-management-app",
  ACCESS_THG_QUOTE_APP = "access-thg-quote-app",
  ACCESS_VIRTA = "access-virta",
  ACCESS_GAAS = "access-gaas",
  ACCESS_WESTFALEN_ID_ACCOUNT_MANAGEMENT_APP = "access-westfalen-id-account-management-app",
  PREVIEW_ALL_APPS = "preview-all-apps",
}
