import { createClient } from "@/shared/urql/urql";
import {
  UpdateUserDataDocument,
  type UserDataInput,
} from "@/shared/generated/graphql";
import { executeMutation } from "@/shared/helper";

export const updateUserData = async (userData: UserDataInput) => {
  const urqlClient = createClient();

  const error = await executeMutation(
    (data) => data?.updateUserData,
    () =>
      urqlClient
        .mutation(UpdateUserDataDocument, {
          userData,
        })
        .toPromise(),
  );
  if (error) throw error;
};
