import action from "./action.json";
import address from "./address.json";
import component from "./component.json";
import corporate from "./corporate.json";
import electricityPrice from "./electricity-price.json";
import error from "./error.json";
import form from "./form.json";
import header from "./header.json";
import layout from "./layout.json";
import maintenance from "./maintenance.json";
import notifications from "./notifications.json";
import service from "./service.json";
import term from "./term.json";
import unit from "./unit.json";

const shared = {
  ...action,
  ...address,
  ...component,
  ...corporate,
  ...electricityPrice,
  ...error,
  ...form,
  ...header,
  ...layout,
  ...maintenance,
  ...notifications,
  ...service,
  ...term,
  ...unit,
};

export default shared;
