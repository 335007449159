import { ref, reactive, readonly } from "vue";
import type { Notification } from "@/shared/@types";

const toasts = reactive<Array<Notification>>([]);
const snackbar = ref<Notification | undefined>();

export const useNotifications = () => {
  const removeToast = (toast: Notification) => {
    toasts.splice(toasts.indexOf(toast), 1);
  };

  const removeAllToasts = () => {
    toasts.splice(0, toasts.length);
  };

  const removeSnackbar = () => {
    snackbar.value = undefined;
  };

  /* Toast -  it's possible to have more than one toast on screen at a time */
  const addToast = (text: Notification["text"], duration = 4000) => {
    removeSnackbar();
    const toast: Notification = {
      text,
      type: "toast",
      variant: "default",
      duration,
    };
    toasts.push(toast);
    setTimeout(() => removeToast(toast), toast.duration);
  };

  /* Snackbar - only one snackbar may be displayed at a time; new one replaces previous */
  const addSnackbarFactory =
    (variant: Notification["variant"]) =>
    (text: Notification["text"], duration = 4000) => {
      removeAllToasts();
      const item: Notification = { text, type: "snackbar", variant, duration };
      snackbar.value = item;
      setTimeout(() => removeSnackbar(), item.duration);
    };

  const addSnackbar = (
    text: Notification["text"],
    variant: Notification["variant"],
    duration = 4000,
  ) => addSnackbarFactory(variant)(text, duration);

  const addInfoSnackbar = addSnackbarFactory("info");
  const addSuccessSnackbar = addSnackbarFactory("success");
  const addWarningSnackbar = addSnackbarFactory("warning");
  const addErrorSnackbar = addSnackbarFactory("error");

  return {
    toasts: readonly(toasts),
    snackbar: readonly(snackbar),
    addToast,
    addSnackbar,
    addInfoSnackbar,
    addSuccessSnackbar,
    addWarningSnackbar,
    addErrorSnackbar,
  };
};
