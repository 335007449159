import base from "./base.json";
import cardsOverview from "./cards-overview.json";
import transactions from "./transactions.json";

const wscManagement = {
  ...base,
  ...cardsOverview,
  ...transactions,
};

export default wscManagement;
