import type { App, Plugin } from "vue";

export type PluginDescription = Plugin | [Plugin, ...Array<unknown>];

export function usePlugins(app: App, plugins: Array<PluginDescription>) {
  plugins.forEach((plugin) => {
    if (plugin instanceof Array) {
      const pluginOptions = plugin.slice(1);
      app.use(plugin[0], ...pluginOptions);
    } else {
      app.use(plugin);
    }
  });
}
