import type { Permissions } from "@/shared/generated";
import { Roles } from "@/shared/@types";

export const getUserRoles = (
  permissions: Permissions,
  selectedCustomerId: string,
) => {
  const roles: Array<Roles> = [];
  if (permissions.fleetAtHomeUserCustomerId === selectedCustomerId) {
    roles.push(Roles.FLEET_AT_HOME_USER);
  }
  if (permissions.fleetAtHomeManagerCustomerIds.includes(selectedCustomerId)) {
    roles.push(Roles.FLEET_AT_HOME_MANAGER);
  }
  if (permissions.wccManagerCustomerIds.includes(selectedCustomerId)) {
    roles.push(Roles.WCC_MANAGER);
  }
  if (permissions.wscManagerCustomerIds.includes(selectedCustomerId)) {
    roles.push(Roles.WSC_MANAGER);
  }
  if (permissions.thgQuoteManagerCustomerIds.includes(selectedCustomerId)) {
    roles.push(Roles.THG_QUOTE_MANAGER);
  }
  if (permissions.virtaUserCustomerIds.includes(selectedCustomerId)) {
    roles.push(Roles.VIRTA_USER);
  }
  if (permissions.gaasUserCustomerIds.includes(selectedCustomerId)) {
    roles.push(Roles.GAAS_USER);
  }
  if (
    permissions.westfalenIdAccountManagerCustomerIds.includes(
      selectedCustomerId,
    )
  ) {
    roles.push(Roles.WESTFALEN_ID_ACCOUNT_MANAGER);
  }

  return roles;
};
