import changePassword from "./change-password.json";
import forgotPassword from "./forgot-password.json";
import login from "./login.json";

const salesforcePages = {
  ...changePassword,
  ...forgotPassword,
  ...login,
};

export default salesforcePages;
